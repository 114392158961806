<template>
  <div id="dunning-application">
    <top-bar @toggleNav="toggleSidebar" />
    <div
      id="dunning-content-wrapper"
      class="d-flex"
    >
      <side-bar
        class="flex-shrink-0"
        :open="sidebarOpen"
      />
      <div
        id="dunning-content-container"
        class="flex-grow-1 mw-100"
        :class="{ open: sidebarOpen }"
        :style="this.$store.state.platform.styling.app"
      >
        <title-bar />
        <div
          id="dunning-content"
          class="d-flex"
        >
          <filter-bar :open="filterBarOpen">
            <component :is="$route.meta.filterBar" />
          </filter-bar>
          <right-bar :open="rightBarOpen">
            <component
              :is="rightBarComponent"
              v-bind="rightBarProperties"
            />
          </right-bar>
          <main
            class="p-3 container-fluid"
            @scroll="handleScroll"
          >
            <router-view />
          </main>
          <support-bar :open="supportBarOpen" />
          <subnav-bar :open="subnavBarOpen">
            <component :is="$route.meta.subnavBar" />
          </subnav-bar>
        </div>
        <footer-bar />
      </div>
      <modal />
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import { storage } from '@/services/LocalStorage';

import TitleBar from '@/components/Title.vue';
import TopBar from '@/components/bars/TopBar.vue';
import SideBar from '@/components/bars/SideBar.vue';
import FooterBar from '@/components/bars/FooterBar.vue';
import SubnavBar from '@/components/bars/SubnavBar.vue';
import FilterBar from '@/components/bars/FilterBar.vue';
import RightBar from '@/components/bars/RightBar.vue';
import SupportBar from '@/components/bars/SupportBar.vue';
import { EventBus } from '@/services/EventBus';
import { debounce } from 'lodash';

@Component({
  components: {
    TitleBar,
    TopBar,
    SideBar,
    FooterBar,
    SubnavBar,
    RightBar,
    FilterBar,
    SupportBar,
  },
})
export default class Main extends Vue {
  private sidebarOpen = true;

  private handleScroll = debounce(() => EventBus.$emit('container-scrolled'), 300, { leading: true, trailing: false });

  @Watch('$store.state.auth.token')
  public tokenChanged(token: string) {
    if (!token) {
      this.$router.replace({ name: 'auth.login' });
    }
  }

  public async created() {
    const impersonateUsername = storage.get<string>('auth.impersonateUsername');
    if (impersonateUsername) {
      await this.$store.dispatch('auth/impersonate', impersonateUsername);
    }
    this.getDashboardData();
    this.getDebtorData();
    this.getDebtorGroupData();
    // Initialize state from route metadata
    this.rightBarMetaChanged();
    this.subnavBarMetaChanged();
  }

  @Watch('$route.path')
  public getDashboardData() {
    let force = this.$route.name?.indexOf('dashboard') !== -1;
    this.$store.dispatch('task/loadData', { force });
  }

  @Watch('$route.params.debtorId')
  public getDebtorData() {
    if (this.$route.params.debtorId) {
      this.$store.dispatch('debtor/loadCurrentDebtor', this.$route.params.debtorId);
    } else {
      this.$store.state.debtor.currentDebtor = null;
    }
  }

  @Watch('$route.params.groupId')
  public getDebtorGroupData() {
    if (this.$route.params.groupId) {
      this.$store.dispatch('debtorGroup/loadCurrentDebtorGroup', this.$route.params.groupId);
    } else {
      this.$store.state.debtorGroup.currentDebtorGroup = null;
    }
  }

  public toggleSidebar() {
    this.sidebarOpen = !this.sidebarOpen;
  }

  get filterBarOpen() {
    return this.$route.meta?.filterBar && this.$store.state.bars.filterBarOpen;
  }

  get subnavBarOpen() {
    return this.$store.state.bars.subnavBarOpen;
  }

  @Watch('$route.meta.subnavBar')
  private subnavBarMetaChanged() {
    if (this.$route.meta?.subnavBar != null) {
      return this.$store.dispatch('bars/openBar', 'subnavBar');
    }

    this.$store.dispatch('bars/closeBar', 'subnavBar');
  }

  @Watch('$route.meta.rightBar')
  private rightBarMetaChanged() {
    if (this.$route.meta?.rightBar != null) {
      return this.$store.dispatch('bars/openRightBar', {
        component: this.$route.meta?.rightBar,
      });
    }

    this.$store.dispatch('bars/closeBar', 'rightBar');
  }

  private get rightBarOpen() {
    return this.$store.state.bars.rightBarOpen;
  }

  private get rightBarComponent() {
    return this.$store.state.bars.rightBarComponent;
  }

  private get rightBarProperties() {
    return this.$store.state.bars.rightBarProperties;
  }

  private get supportBarOpen() {
    return this.$store.state.bars.supportBarOpen;
  }
}
</script>

<style lang="scss" scoped>
@import '~@/assets/scss/variables';
@import '~@/assets/scss/colors';
#dunning-content-wrapper {
  position: fixed;
  z-index: 20;
  top: $topbar-height;
  bottom: 0;
  left: 0;
  right: 0;
  & > #dunning-content-container {
    transition: ease $speed;
    margin-left: $sidebar-small;
    &.open {
      margin-left: $sidebar-large;
    }
    overflow: auto;
    background-color: $bg-body;
    display: flex;
    flex-direction: column;
    & > #dunning-content {
      height: 100%;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      overflow: auto;
      // adding next row to fix Safarig bug autoprefixer
      & > .container-fluid {
        overflow-y: scroll;
      }
    }
  }
}
</style>
