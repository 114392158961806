import { Module } from 'vuex';

import { RootState} from '@/models';
import api from '@/api';
import { TaskCounts, TaskState } from '@/models/TaskState';

export const task: Module<TaskState, RootState> = {
  namespaced: true,
  state: {
    lastUpdate: null,
    loading: false,
    counts: null,
  },
  actions: {
    async loadData({ state, commit, dispatch }, options) {
      commit('setLoading', true);
      // either forced, not updated, or update older than 60 seconds.
      if (options.force === true || state.lastUpdate === null || (Date.now() - state.lastUpdate) > 60000) {
        await api.get('/task-count')
          .then(({ data }) => commit('setTaskCounts', data.tasks))
          .catch(() => dispatch('throwError', { msg: 'Did not receive the correct parameters' }, { root: true }));
        commit('setLoading', false);
      }
    },
    unloadData({ commit }) {
      commit('unsetData');
    },
  },
  mutations: {
    setLoading(state, loading: boolean) {
      state.loading = loading;
    },
    setTaskCounts(state, data: TaskCounts) {
      state.counts = data;
      state.lastUpdate = Date.now();
    },
    unsetData(state) {
      state.loading = false;
      state.counts = null;
      state.lastUpdate = null;
    },
  },
};
